.page-wrapper--statutes .categories ul,
.page-wrapper--statutes .items ul,
.page-wrapper--statutes .filter-results ul {
  padding: 0
}
.page-wrapper--statutes .categories ul li a,
.page-wrapper--statutes .items ul li a,
.page-wrapper--statutes .filter-results ul li a {
  display: block;
  text-decoration: none;
  color: #003268;
  -webkit-transition: color .25s linear;
  -moz-transition: color .25s linear;
  -ms-transition: color .25s linear;
  -o-transition: color .25s linear;
  transition: color .25s linear
}
.page-wrapper--statutes .categories ul li a:hover,
.page-wrapper--statutes .items ul li a:hover,
.page-wrapper--statutes .filter-results ul li a:hover {
  color: #00a0e0
}
.page-wrapper--statutes .categories {
  padding-right: 15px
}
.page-wrapper--statutes .categories ul {
  list-style: none;
  margin: 0
}
.page-wrapper--statutes .categories ul li a {
  padding: 8px 0;
  border-bottom: 1px solid #d3d3d3;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  font-size: 1.6rem
}
.page-wrapper--statutes .categories ul li:first-child a {
  border-top: 1px solid #d3d3d3
}
.page-wrapper--statutes .categories ul li.active a {
  color: #00a0e0
}
.page-wrapper--statutes .categories+.languages {
  margin-top: 75px
}
.page-wrapper--statutes .categories,
.page-wrapper--statutes .filter-results {
  padding-right: 15px
}
.page-wrapper--statutes .categories ul,
.page-wrapper--statutes .filter-results ul {
  list-style: none;
  margin: 0
}
.page-wrapper--statutes .categories ul li a {
  padding: 8px 0;
  border-bottom: 1px solid #d3d3d3;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  font-size: 1.6rem
}
.page-wrapper--statutes .categories ul li:first-child a {
  border-top: 1px solid #d3d3d3
}
.page-wrapper--statutes .categories ul li.active a {
  color: #00a0e0
}
.page-wrapper--statutes .categories+.languages {
  margin-top: 75px
}

.page-wrapper--statutes h1 {
  margin-bottom: 45px;
}
.page-wrapper--statutes .button-container { margin-top: 50px;}
.page-wrapper--statutes .categories .button { margin-bottom: 15px; width: 100%; text-align: center; padding: 14px 25px;}