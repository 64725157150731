@import 'theme-style';
@import 'events';
@import 'members';
@import 'newsletter';
@import 'statutes';

// Home page
.halfcover.halfcover--wide {
  width: 55%;

  .infos { width: 584px;}
  .infos:after {
    content: '';
    clear: both;
    display: block;
  }
  .newsletter__c1,.newsletter__c2 {
    float: left;
  }
  .newsletter__c1 {
    width: 40%;
  }
  .newsletter__c2 {
    width: 60%;

    input[type="text"], input[type="email"] { color: rgb(76, 76, 76) }
  }

  .newsletter__online .more { margin-top: 30px;}

}
@media(max-width: 800px) {
  // Newsletter
  #news .halfcover.halfcover--wide { width: auto;}
  #news .halfcover.halfcover--wide .infos { width: auto;}
  #news .newsletter__c1, #news .newsletter__c2 { float: none; width: auto;}
  #news.content [class*="col-sm"] + [class*="col-sm"] { margin-top: 15px; }
}

// Buttons
.button--white {
  color: #2c568d;
  background: white;
}

// People
.people-item h2 { font-size: 17px; font-weight: bold;}
.people-item h3 { font-size: 17px; line-height: 30px;}
.content .people-items .elected .category, .content .people-items__more a { height: 331px;}

// Social
.social__container { margin: 0 auto; margin-top: 30px; width: 80%; }
.social__header {
  background: linear-gradient(#3c61af, #4570ca);
  padding: 10px 0px;
}
.social__content {
  background: url('images/social-bg.jpg') no-repeat;
  background-size:cover;
  padding: 20px 150px;
}
.cff-post-text { text-align: left}
.prev, .next { font-size: 32px; color: white; margin-left: 15px; opacity: 0.8}
.facebook-feed__nav { text-align: right;}
.feed-icon { display: none;}


// General
h4 { color: #00a0e0; font-weight:bold;}
h4 p { color: #4c4c4c; font-weight: normal;}
.content--bgc12 { background:  #f4f6fa }
div.wpcf7-response-output { margin: 0; }
.page-footer h1.logo { float:none; margin: 0; margin-bottom: 20px;}
.page-footer h1.logo a {
  background-position: left;
}


@import 'media.scss';