@media (min-width: 0px) {
  // Social Facebook block
  .social__content { padding: 15px;}
  .cff-item { }
  .cff-item { float: none; width: auto;}
  .cff-text-wrapper { float:none; width: auto;}

}

@media(min-width: 800px) {
  .social__content { padding: 20px 150px;}
}