.event-details {
  display: none;
}

.filters {
  padding: 35px 0;
  text-align: center
}
.filters select,
.filters button {
  vertical-align: top
}
.filters ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0
}
.filters ul li {
  display: inline-block;
  margin: 10px
}
.filters ul li a {
  display: block;
  padding: 15px 35px;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #00a0e0;
  border: 2px solid #00a0e0;
  font-weight: 600;
  font-style: normal;
  font-size: 13px;
  font-size: .8666666667rem;
  -webkit-transition: color .25s linear, background-color .25s linear;
  -moz-transition: color .25s linear, background-color .25s linear;
  -ms-transition: color .25s linear, background-color .25s linear;
  -o-transition: color .25s linear, background-color .25s linear;
  transition: color .25s linear, background-color .25s linear
}
.filters ul li a:hover {
  color: #fff;
  background-color: #00a0e0
}
.filters ul li.active a {
  color: #fff;
  background-color: #00a0e0
}
@media (max-width: 767px) {
  .filters ul li {
    display: block;
    width: 100%
  }
}
.filters--c02 ul li a {
  color: #faaf37;
  border-color: #faaf37
}
.filters--c02 ul li a:hover {
  background-color: #faaf37
}
.filters--c02 ul li.active a {
  background-color: #faaf37
}
.filters--bgc10 {
  background-color: #5fadd2
}
.filters--bgc10 ul li a {
  color: #fff;
  border-color: #fff
}
.filters--bgc10 ul li a:hover {
  color: #5fadd2;
  background-color: #fff
}
.filters--bgc10 ul li.active a {
  color: #5fadd2;
  background-color: #fff
}


// Tools
.tools .content {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  padding: 20px 0;
  margin-top: 50px;
}
.tools .content [class*="col-sm"]:last-child {
  text-align: right;
}
.tools .event-actions a { margin-right: 20px;}
.page-wrapper--article-details .nav-articles.nav-articles--sm-margin {
  margin-top: 30px;
}
.nav-articles--events .prev { margin-left:0px;}
.prev, .next { margin-left: 0px;}

.sharethis {
  display: inline-block;
  overflow: hidden
}
.sharethis p,
.sharethis ul {
  float: left
}
.sharethis p {
  margin-right: 18px;
  line-height: 30px;
  text-transform: uppercase;
  color: #003268;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  font-size: .8rem
}
.sharethis p .fa {
  margin-right: 4px
}
.sharethis ul {
  overflow: hidden;
  list-style: none;
  margin: 0 !important;
  padding: 0
}
.sharethis ul li {
  float: left
}
.sharethis ul li+li {
  margin-left: 7px;
  margin-top: 0
}
.sharethis ul li.facebook a {
  color: #4672cc;
  border-color: #4672cc
}
.sharethis ul li.facebook a:hover {
  background-color: #4672cc
}
.sharethis ul li.twitter a {
  color: #60aed3;
  border-color: #60aed3
}
.sharethis ul li.twitter a:hover {
  background-color: #60aed3
}
.sharethis ul li.linkedin a {
  color: #3894bf;
  border-color: #3894bf
}
.sharethis ul li.linkedin a:hover {
  background-color: #3894bf
}
.sharethis ul li.pinterest a {
  color: #cb2027;
  border-color: #cb2027
}
.sharethis ul li.pinterest a:hover {
  background-color: #cb2027
}
.sharethis ul li a {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  -webkit-transition: color .25s linear, background-color .25s linear;
  -moz-transition: color .25s linear, background-color .25s linear;
  -ms-transition: color .25s linear, background-color .25s linear;
  -o-transition: color .25s linear, background-color .25s linear;
  transition: color .25s linear, background-color .25s linear
}
.sharethis ul li a:hover {
  color: #fff
}
@media (max-width: 767px) {
  .sharethis p,
  .sharethis ul {
    float: none
  }
  .sharethis p {
    margin-right: 0
  }
  .sharethis ul {
    margin-top: 10px
  }
}