.ml__tabs {
  &:after {
    clear: both;
    content: '';
    display: block;
  }
}

.ml__tab {
  color: white;
  float: left;
  line-height: 58px;
  width: 50%;
  text-align: center;
  background: #00b3eb;
  text-transform: uppercase;
  &.active { background: #00a0e0;}
  cursor: pointer;
}

.ml__cols {
  padding: 50px 0px;
  display: none;

  &.active { display: block;}
}

.ml__col {
  padding: 0;
  margin: 0;
  float: left;
  padding-left: 70px;
  width: 50%;
  li {
    cursor: pointer;
    list-style: none;
    margin-bottom: 10px;
  }
  img {
    margin-right: 10px;
  }
}

.page-wrapper--members .panel .views .member-list{
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  width: 580px;
  height: 800px; }

.page-wrapper--members .panel .views .member-list {
  left: 1160px;
}
.page-wrapper--members .panel .views .scrollview.p-details-2 {
  transform: translateX(-1160px);
}
.page-wrapper--members .panel .views .party .body .main .website {
  max-width: 300px;
}




.member__back { float: right; text-transform: uppercase;
  cursor: pointer; transition: all 150ms;
  line-height: 26px;
}
.member__back:hover { color: #003268;}
.member__back--white:hover { color: white;}