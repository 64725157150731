.news-item+.news-item {
  margin-top: 15px
}
.news-item a {
  display: block;
  position: relative;
  padding-left: 180px;
  padding-right: 80px;
  line-height: 50px;
  text-decoration: none;
  color: #003268;
  background-color: white;
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  font-size: 1.2rem;
  -webkit-transition: background-color .25s linear;
  -moz-transition: background-color .25s linear;
  -ms-transition: background-color .25s linear;
  -o-transition: background-color .25s linear;
  transition: background-color .25s linear
}
.news-item a::after {
  content: "\f2c7";
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  width: 50px;
  height: 50px;
  font-family: "Ionicons";
  font-size: 38px;
  text-align: center;
  color: #fff;
  background-color: #00a0e0;
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%)
}
.news-item a time,
.news-item a .subtitle {
  font-weight: 700;
  font-style: normal
}
.news-item a time {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 150px;
  height: 100%;
  text-align: center;
  color: #fff;
  background-color: #00a0e0
}
.news-item a time::after {
  content: "";
  display: block;
  position: absolute;
  right: -11px;
  top: 50%;
  margin-top: -11px;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-left: 11px solid #00a0e0
}
.news-item a .subtitle {
  color: #00a0e0;
  -webkit-transition: color .25s linear, background-color .25s linear;
  -moz-transition: color .25s linear, background-color .25s linear;
  -ms-transition: color .25s linear, background-color .25s linear;
  -o-transition: color .25s linear, background-color .25s linear;
  transition: color .25s linear, background-color .25s linear
}
.news-item a:hover {
  background-color: #00a0e0
}
.news-item a:hover .subtitle {
  color: #fff
}
.news-item--hl a {
  background-color: #fef0d3
}
.news-item--hl a::after {
  background-color: #faaf37
}
.news-item--hl a time {
  background-color: #faaf37
}
.news-item--hl a time::after {
  border-left-color: #faaf37
}
.news-item--hl a .subtitle {
  color: #faaf37
}
.news-item--hl a:hover {
  background-color: #faaf37
}
@media (min-width: 992px) and (max-width: 1199px) {
  .news-item a {
    padding-left: 150px;
    font-size: 16px;
    font-size: 1.0666666667rem
  }
  .news-item a::after {
    font-size: 32px
  }
  .news-item a time {
    width: 120px
  }
}
@media (max-width: 991px) {
  .news-item+.news-item {
    margin-top: 55px
  }
  .news-item a {
    padding: 40px 15px 30px;
    line-height: 1.4em;
    text-align: center
  }
  .news-item a::after {
    content: none
  }
  .news-item a time {
    left: 50%;
    top: -25px;
    height: auto;
    padding: 15px 0;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%)
  }
  .news-item a time::after {
    content: none
  }
}
.pagination {
  margin-top: 60px;
  text-align: center
}
.pagination ul {
  display: inline-block;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0
}
.pagination ul li {
  display: block;
  float: left;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center
}
.pagination ul li+li {
  margin-top: 0
}
.pagination ul li a,
.pagination ul li span {
  display: block;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  font-weight: bold
}
.pagination ul li a {
  text-decoration: none;
  color: #00a0e0;
  -webkit-transition: color .25s linear;
  -moz-transition: color .25s linear;
  -ms-transition: color .25s linear;
  -o-transition: color .25s linear;
  transition: color .25s linear
}
.pagination ul li a:hover {
  color: #faaf37
}
.pagination ul li .current {
  color: #faaf37
}



.filter_years li {
  display: inline-block;
  padding: 5px 20px;
  border: 1px solid #285087;
  color: #285087;
  font-weight: bold;
  margin-right: 10px;
  text-align: center;

  &.selected {
    color: white;
    background: #285087;
  }
}

// Custom selector (copy from EPP)
.customSelect {
  display: inline-block;
  margin-right: 30px;
  height: 46px;
  font-weight: 500;
  line-height: 1.2em;
  color: #4c4c4c;
  background-color: #fff;
  pointer-events: none;
  z-index: 999
}
.customSelect--gray {
  background-color: #f0f0f0
}
.customSelect.fit-parent {
  width: 100% !important
}
.customSelect.fit-parent .customSelectInner {
  width: 100% !important
}
.customSelectInner {
  position: relative;
  width: auto !important;
  padding: 14px 56px 14px 16px;
  overflow: hidden;
  white-space: nowrap
}
.customSelectInner::before {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 100%;
  background-color: #003268
}
.customSelectInner::after {
  content: "";
  display: block;
  position: absolute;
  right: 14px;
  top: 50%;
  margin-top: -4px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #fff
}
select.hasCustomSelect {
  cursor: pointer;
  z-index: 9999
}